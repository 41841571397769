export default [
  {
    username: "iris.the.cat",
    userImage: "hard-coded",
    postImage:
      "./posts/IMG_1323.jpeg",
    likes: 36,
    hasBeenLiked: false,
    caption: "me and my oldest sister hunting for rabbits",
    filter: "perpetua"
  },
  {
    username: "iris.the.cat",
    userImage: "hard-coded",
    postImage:
      "./posts/IMG_9998.jpg",
    likes: 36,
    hasBeenLiked: false,
    caption: "i am a wild one #rawr",
    filter: "perpetua"
  },
  {
    username: "iris.the.cat",
    userImage: "hard-coded",
    postImage:
      "./posts/IMG_6479.jpeg",
    likes: 20,
    hasBeenLiked: false,
    caption: "hey! no, don't do that #nopaparazzispls",
    filter: "clarendon"
  },
  {
    username: "iris.the.cat",
    userImage: "hard-coded",
    postImage:
      "./posts/F7011A73-BA4A-4527-9F02-3321FDE40C84.JPG",
    likes: 49,
    hasBeenLiked: false,
    caption: "thinking about that mice i couldn't catch",
    filter: "lofi"
  },
  {
    username: "iris.the.cat",
    userImage: "hard-coded",
    postImage:
      "./posts/1f695d0b-f99b-4d8b-a2d9-2b0e479068b5.JPG",
    likes: 49,
    hasBeenLiked: false,
    caption: "someone tell him to let me go before i scratch his eye",
    filter: "lofi"
  },
  {
    username: "iris.the.cat",
    userImage: "hard-coded",
    postImage:
      "./posts/IMG_9893.jpeg",
    likes: 49,
    hasBeenLiked: false,
    caption: "catching up on zzz's",
    filter: "mayfair"
  },
  {
    username: "iris.the.cat",
    userImage: "hard-coded",
    postImage:
      "./posts/IMG_9945.jpeg",
    likes: 49,
    hasBeenLiked: false,
    caption: "seeee, i told you i am veryyyy tall",
    filter: "mayfair"
  },
  {
    username: "iris.the.cat",
    userImage: "hard-coded",
    postImage:
      "./posts/IMG_9712.JPG",
    likes: 49,
    hasBeenLiked: false,
    caption: "i wonder if people even realize how great i am",
    filter: "mayfair"
  }
];
